<template>
  <v-container id="login" fill-height fluid style="overflow=hidden;">
    <router-view></router-view>
  </v-container>
</template>
<script>
// import cellsimPortalService from '../../api/cellSim';

export default {
  name: "authentication",
};
</script>
<style scoped>
#login {
  /* background-image: url(https://picsum.photos/1200/?random&gravity=east); */
  /* background-image: url('https://s3.amazonaws.com/4.0image/cellsimBG.jpg'); */
  /* background-image: url('https://media.istockphoto.com/id/1221576414/photo/background-abstract-teal-and-black-dark-are-light-with-the-gradient-is-the-surface-with.jpg?s=170667a&w=0&k=20&c=H7f_wEyCKdRfugk_1A6zd9K66MUHJDwFPYjdeez68b0=');
  background-size: cover; */
  background-color: #F6F6F6;
}
</style>
